.carousel-control-prev
{
    overflow: hidden;
    padding-top: 0 !important;
    z-index: 22;
    left: 0 !important;
}
.carousel-control-next
{
    padding-top: 0 !important;

    overflow: hidden;
    z-index: 22;
    right: 0 !important;

}
.fs{
    position:absolute;
    width:70%;
    left: 15%;
    top:5%;
    margin-top:auto; 
    background:#fff; 
    z-index:1001;
}

@media (max-width: 767px) {
    .fs{
        position:fixed;
        width:99%;
        left: 0%;
        margin:auto; 
        background:#fff; 
        z-index:1001;
    }
}