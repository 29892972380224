.banner{
    animation:move_banner 11.1s linear;
}
.imagen-con-extremos-difuminados {
    width: 75%; /* Ajusta el ancho según tus necesidades */
    margin: auto;
        position: relative;
    
}


@media (max-width: 767px) {
    .imagen-con-extremos-difuminados {
        width:200% !important; /* Ajusta el ancho según tus necesidades */

        
    }
    
}
.instagram{
    width: 65.8% !important;
  }
  
  @media (max-width: 767px) {
    .instagram{
        width: 100% !important;
    }
  }

.imagen-con-extremos-difuminados::before,
.imagen-con-extremos-difuminados::after {
    content: "";
    z-index: 9;
    position: absolute;
    top: 0;
    width: 70px; /* Ancho de los extremos difuminados (ajusta según tu preferencia) */
    height: 100%;
}

.imagen-con-extremos-difuminados::before {
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)); /* Gradiente blanco para difuminar */
    left: 0;
}

.imagen-con-extremos-difuminados::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)); /* Gradiente blanco para difuminar */
    right: 0;
}

@keyframes move_banner{
    0%{

        transform: translateX(0%);
 
        
    
    }
   
    100%{

        transform: translateX(-4%);



    }

    
}
