@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
h1, h2{
    font-size: x-large;
}
h3{
    font-size: large;
}
@media (max-width: 767px) {
    h1{
        font-size: large !important;
    }
    h3, h2{
        font-size: medium !important;
    }
    a{
        font-size: small !important;
    }

}
.footer-img {
    width: 100%;
    max-width: 150px;
    filter: invert(50%);
    margin: auto;
    z-index: 5;
  }
  
  @media (max-width: 767px) {
    .footer-img {
      max-width: 100px;
    }
  }
  p{
    font-size: medium;
  }
.sma{
    font-size: small;
}
li, a{
    font-size: medium;
}
.nave{
    background-image:url(../imagenes/logohead.png);
    background-position:center;
    background-repeat:no-repeat;
    background-size:45px;

}
.header:hover logop{
    opacity: 1;
}
h2{
    text-align: center;
}
.cartel{
    text-align: justify !important;
}
.header{
    background-image:url(../imagenes/infodesc.png);
    background-position:0% 0% ;
    background-repeat:repeat;
    background-size: 130%;
    animation: move_header 15s linear infinite;
}
@keyframes move_header{
    0%{

        box-shadow:inset 0px 40px 50px 10px rgb(255, 255, 255);
        background-position: 0% 10%;
        
    
    }
   
    100%{



        box-shadow:inset 0px 40px 50px 10px rgb(255, 255, 255);

        background-position:100% 110%;
        



    }

    
}
