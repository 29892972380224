.cartel{
    text-align: justify !important;

}
.cnt{
    min-height: 480px;

}
.fade-in {
    animation: fadeInAnimation ease 1s;
    animation-fill-mode: forwards;
}
.fade-in-sl {
    animation:fadeInAnimation1 5s ease-in-out infinite;
    
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
     }
}
@keyframes fadeInAnimation1 {
    0% {
        opacity: 0.0;
    }
    20%{
        opacity: 1;

    }
    80% {
        opacity: 0.9;

     }
     100%{
         opacity: 0;
     }
}



  
  