.tag{

    margin-bottom: 15px;
    border-bottom-left-radius: 53px 13px;
    border-bottom-right-radius: 53px 13px;
    box-shadow: 0px 4.5px 5px -5.2px #645c5ca8;
    color: #636363;
    margin-bottom: 25px;

}


.tag h2{
    margin: 0;
    padding: 0;
    line-height: 90%;
    

    letter-spacing: 5px;
    
    display: block;
    /* border-bottom-left-radius: 35px 35px;
    border-bottom-right-radius: 35px 35px; */

    background-color: #fff;

}

.list-group-item{
    background-color: transparent;
}
